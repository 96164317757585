import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducers from "../reducer";
import RootSaga from "../saga";

// Added composer to run rudux state in browser
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const configureStore = (initialState) => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancer(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(RootSaga);

  return store;
};

const store = configureStore();

export default store;
